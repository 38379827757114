.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.line-through {
    color: #282c34;
    text-decoration: line-through;
}

.color-red {
    color: red;
}

.user-need-to-re-registration {
    background-color: blue;
    color: white;
}

.table-responsive .column {
    display: table-cell;
    width: 100px;
    left: 0;
    margin: 0;
    background: transparent;
}

.inline {
    display: inline;
}

.button-height {
    margin: 0;
    height: 25px;
}

.lessonnote {
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 90%;
}

.extend-icon {
    width: 14px;
    height: 14px;
}

.display-enter {
    white-space: pre-line;
  }

.no-overflow-x{
    overflow-x: hidden;
    overflow-y: auto;
}

.word-break-all{
    word-break: break-all;
}

.bg-yellow
{
    background-color: #FFFF00
}

.Modal {
    position: absolute;
    top: 20%;
    left: 30%;
    right: 30%;
    bottom: 30%;
    background-color: rgb(255, 255, 255);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.52);
  }

  .modal {
    position: fixed;
    z-index:9999;
    top :0;
    left:0;
    right:0;
    bottom:0;
    background: #464b5e;
    color: white;
    outline: none;
    padding: 3.2rem;
    text-align: center;
}

.modal-overlay {
    position: fixed;
    /* top: calc(50% - 300px); */
    /* left:calc(50% - 300px); */
    /* width: 600px; */
    /* height: 600px; */
    /* bottom: auto; */
    /* right:auto; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 1000;
    /* font-weight: bold; */
}

@media screen and (max-width: 799px) {
    .modal-content {
        position: fixed;
        top: 10%;
        left: 10%;
        width: calc(100vw - 20%);
        height: calc(100vh - 20% - 50px);
        bottom: auto;
        right:auto;
        background-color: white;
        z-index: 1001;
        overflow: scroll;
        border: 2px gray;
    }

    .modal-close {
        position: fixed;
        top: calc(10% + 100vh - 20% - 50px);
        /* top: 0; */
        left:10%;
        width: calc(100vw - 20%);
        height: 50px;
        color: white;
        background-color: black;
        line-height: 50px;
        /* background-color: red; */
        z-index: 1002;
        user-select: none;
        font-weight: bold;
        float:left;
        text-align: center;
        cursor: pointer;
    }
}

@media screen and (min-width: 800px) {


    .modal-content {
        position: fixed;
        top: calc(50% - 400px);
        left:calc(50% - 400px);
        width: 800px;
        height: 800px;
        bottom: auto;
        right:auto;
        background-color: white;
        z-index: 1001;
        border: 2px gray;
        overflow: scroll;
    }

    .modal-close {
        position: fixed;
        top: calc(50% + 400px);
        left:calc(50% - 400px);
        width: 800px;
        height: 50px;
        color: white;
        background-color: black;
        line-height: 50px;
        /* background-color: red; */
        z-index: 1002;
        user-select: none;
        font-weight: bold;
        float:left;
        text-align: center;
        cursor: pointer;
    }

}

.td-block {
    display: block;
}

.user-before {
    position: fixed;
    text-align: center;
    top: calc(50% - 25px);
    left: 0px;
    bottom: auto;
    right: auto;
    width: 50px;
    height: 50px;
    background-color: rgb(43, 52, 118);
    border: 1px solid white;
    cursor: pointer;
}

.user-after {
    position: fixed;
    text-align: center;
    top: calc(50% - 25px);
    left: calc(100% - 50px);
    bottom: auto;
    right: auto;
    width: 50px;
    height: 50px;
    background-color: rgb(43, 52, 118);
    border: 1px solid white;
    cursor: pointer;
}

.user-navigator-icon {
    color:rgb(255, 255, 255);
    margin-top: 10px;
    width: 30px;
    height: 30px;
}